import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 89",
  week: "Semana 13",
  day: "10",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-10",
  path: "/cronologia/semana-13#dia10-jun/",
};

const Day89 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModCovidHighlight>
          El Reino Unido supera los 290.000 casos confirmados
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en{" "}
          <strong>España</strong> 2.104 casos mediante pruebas PCR. En ese mismo
          periodo, 139 personas han requerido hospitalización (6,6% de los
          diagnósticos), de los que 12 han sido ingresos en la UCI, y se han
          producido 40 fallecimientos.
        </ModText>
        <ModText>
          Los Ministerios de Sanidad y Educación han elaboran un{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/educacion/Paginas/2020/100620-educacion_sanidad.aspx">
            documento
          </InlineLink>{" "}
          que servirá de base para los centros educativos para el curso
          2020-2021 y será presentado mañana a las comunidades autónomas en la
          Conferencia Sectorial de Educación. El objetivo es plantear una
          estrategia consensuada para ofrecer un entorno seguro y saludable al
          alumnado y personal de los centros educativos.
        </ModText>
        <ModImage
          src="/images/svg/colegios-educacion-alumnos.svg"
          alt="alumnos estudiando"
        />

        <ModText>
          El próximo 26 de junio, 255.000 personas recibirán de oficio el{" "}
          <InlineLink link="http://www.seg-social.es/wps/portal/wss/internet/FAQ/48581/42b9f3e8-8eea-4106-864e-2b5bdad27269">
            Ingreso Mínimo Vital
          </InlineLink>
          . Más de la mitad de estos beneficiarios/as son menores de edad.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day89;
